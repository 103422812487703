import React from 'react'
import get from 'lodash/get';
import Img from 'gatsby-image'
import styled from 'styled-components'
import Flickity from 'react-flickity-component'
import { isOffline } from '@utils';
import { FlickityArrow } from '@components';

class ProductContentImages extends React.Component {
  constructor(props) {
    super(props)

    this.flickityRef = React.createRef()
  }

  state = {
    disablePrevButton: true,
    disableNextButton: false,
  }

  handleOnFlickityClick = isLeft => {
    const flickityInstance = this.flickityRef.current.flkty

    flickityInstance.on('change', () => {
      if (flickityInstance.selectedIndex === 0) {
        this.setState({
          disablePrevButton: true,
          disableNextButton: false,
        })
      } else if (flickityInstance.selectedIndex === this.props.images.length - 1) {
        this.setState({
          disablePrevButton: false,
          disableNextButton: true,
        })
      } else {
        this.setState({
          disablePrevButton: false,
          disableNextButton: false,
        })
      }
    })

    if (isLeft) {
      flickityInstance.previous()
    } else {
      flickityInstance.next()
    }
  }

  render() {
    const { images, imagesFullHeight, id } = this.props
    const { disableNextButton, disablePrevButton } = this.state

    if (!Array.isArray(images)) {
      return null;
    }

    return (
      <ProductImages>
        {images.length > 1 ? (
          <FlickityWrapper>
            <FlickityArrows alignment={imagesFullHeight ? 'right' : 'left'}>
              <FlickityArrow
                disabled={disablePrevButton}
                direction="left"
                onClick={() => this.handleOnFlickityClick(true)}
              />
              <FlickityArrow
                disabled={disableNextButton}
                onClick={() => this.handleOnFlickityClick()}
              />

            </FlickityArrows>
            {typeof window !== 'undefined' && (
              <Flickity
                static
                ref={this.flickityRef}
                className={
                  imagesFullHeight
                    ? 'flickity-carousel fullscreen'
                    : 'flickity-carousel'
                }
                options={{
                  prevNextButtons: false,
                }}
              >
                {images.map(({ image }, index) => {
                  if (!image) {
                    return null
                  }

                  const imagePath =
                    image.localFile !== null
                      ? image.localFile.childImageSharp.fixed
                      : image.sourceUrl

                  const imageIsAvailableLocal = image.localFile !== null

                  return (
                    <div key={`product-gallery-${id}-${index}`} className="carousel-cell">
                      {imageIsAvailableLocal ? (
                        <Img
                          fixed={imagePath}
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                          alt=""
                          loading={isOffline ? 'eager' : 'lazy'}
                        />
                      ) : (
                        <img
                          src={imagePath}
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                          alt=""
                        />
                      )}
                    </div>
                  )
                })}
              </Flickity>
            )}
          </FlickityWrapper>
        ) : (
          <React.Fragment>
            {get(images, '[0].image.localFile') ? (
              <Img
                fixed={get(images, '[0].image.localFile.childImageSharp.fixed')}
                style={{
                  height: '100%',
                  width: '100%',
                }}
                alt=""
              />
            ) : (
              <img
                src={images[0].sourceUrl}
                style={{
                  height: '100%',
                  width: '100%',
                }}
                alt=""
              />
            )}
          </React.Fragment>
        )}
      </ProductImages>
    )
  }
}

export default ProductContentImages

const FlickityWrapper = styled.div`
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  user-select: none;
  outline: none;
  position: relative;
`

const FlickityArrows = styled.div`
  display: flex;
  position: absolute;
  top: 38px;
  z-index: 100;

  ${({ alignment }) => alignment === 'left' ? 'left: 38px;' : 'right: 38px;'}

  button {
    margin-right: 20px;
  }
`

const ProductImages = styled.div`
  position: relative;
  width: 50vw;
  height: 100vh;
  user-select: none;

  // Gallery options
  .flickity-carousel {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    user-select: none;
    outline: none;
    position: relative;
  }
  .flickity-viewport {
    overflow: hidden;
    position: relative;
    margin-top: 20vh;
  }

  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .carousel-cell {
    width: 100%;
    height: 60vh;
  }

  .flickity-carousel.fullscreen {
    .flickity-viewport {
      margin-top: 0;
    }

    .carousel-cell {
      height: 100vh;
    }
  }
`
