/**
 * Page Builder
 */

import React, { useState, forwardRef, useContext } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import * as sectionComponents from './sections';
import useWithIds from '@utils/useWithIds';
import ReactFullpage from '@fullpage/react-fullpage';
import setTheme from '@utils/setTheme';
import { ProgressIndicator, ScrollIndicator } from './components';
import AppContext from '@contexts/AppContext';
import css from './Pagebuilder.module.css';

// const Section = ({ 
//   component: Component,
//   state,
//   ...rest
// }) => {
//   return (
//     <Component
//       state={state}
//       {...rest}
//     />
//   );
// };

const Pagebuilder = forwardRef(({ 
  sections: sectionsData,
  wordpressId,
  children,
  showScrollIndicator,
  scrollIndicatorLabel
}, ref) => {
  const { toggleHideControls, hideControls } = useContext(AppContext);
  const fullpageRef = ref;
  const sections = useWithIds(sectionsData);
  const sectionsCount = sections.length;
  const [scrollingDisabled, setScrollingDisabled] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const disableScrolling = (bool) => {
    setScrollingDisabled(bool);
    toggleHideControls(bool);
    fullpageRef.current.fullpageApi.setAllowScrolling(!bool);
  }

  const render = ({ state, fullpageApi } = {}) => (data, index) => {
    const { __typename } = data;
    const key = __typename.split('_')[__typename.split('_').length - 1];
    const Component = sectionComponents[key] || sectionComponents.Default;

    return (
      <Component 
        {...data}
        inView={activeIndex === index}
        disableScrolling={disableScrolling}
        key={`${key}-${index}`}
      />
    );
  };

  if (!sectionsCount) {
    return (
      <div className={css.noSections}>
        <h1>
          No sections added
        </h1>
        <a target="_blank" rel="noreferrer" href={`${process.env.GATSBY_WORDPRESS_SOURCE}/wp-admin/post.php?post=${wordpressId}&action=edit`}>Click here to edit this page</a>
      </div>
    );
  }

  const renderSections = ({ state, fullpageApi }) => {
    return (
      <ReactFullpage.Wrapper>
        {sections.map(render({ state, fullpageApi }))}
      </ReactFullpage.Wrapper>
    );
  };

  const renderProgressIndicator = () => {
    if (scrollingDisabled || hideControls) {
      return null;
    }

    const onItemClick = (index) => {
      fullpageRef.current.fullpageApi.moveTo(index + 1);
    }

    return (
      <ProgressIndicator
        itemType="dot"
        selected={activeIndex}
        items={sectionsCount}
        onItemClick={onItemClick}
        presentationMode={false}
      />
    );
  };

  const renderScrollIndicator = () => {
    if (!showScrollIndicator || hideControls) {
      return null;
    }

    return (
      <ScrollIndicator
        label={scrollIndicatorLabel}
        show={activeIndex === 0 && sectionsCount > 1}
      />
    );
  }

  const onAfterLoad = (origin, destination, direction) => {
    setTheme(get(destination, 'item'));
  }

  const onLeave = (origin, destination, direction) => {
    setActiveIndex(destination.index);
    window.history.replaceState(undefined, undefined, `#${destination.index + 1}`);
  }

  return (
    <>
      <ReactFullpage
        className={css.root}
        licenseKey="AF791051-81584285-ACFA00CE-D7983C24"
        onLeave={onLeave}
        afterLoad={onAfterLoad}
        ref={fullpageRef}
        render={renderSections}
        scrollingSpeed={500}
        normalScrollElements=".overlay"
      />
      {renderProgressIndicator()}
      {renderScrollIndicator()}
      {typeof children === 'function' && children({ activeIndex, hideControls })}
    </>
  );
});

export default Pagebuilder;

export const pageBuilderFragment = graphql`
  fragment Pagebuilder on WpPage {
    pagebuilder {
      sections {
        __typename
        ...PagebuilderContent
        ...PagebuilderFullscreen
        ...PagebuilderHero
        ...PagebuilderIntro
        ...PagebuilderMenu
        ...PagebuilderProducts
        ...PagebuilderStreaming
        ...PagebuilderVideo
        ...PagebuilderWearing
        ...PagebuilderTextual
      }
    }
  }
`;
