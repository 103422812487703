/**
 * Streaming -> Data formatter
 */

import { v4 as uuid } from 'uuid';

const dataFormatter = data => {
  return {
    id: data.streamingId || uuid(),
    logo: data.streamingLogo,
    background: data.streamingBackgroundImage,
    episodes: data.streamingEpisodes,
    back_link: data.streamingBackLink,
  };
}

export default dataFormatter;
