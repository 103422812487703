import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { cubicBezier, MQ } from '../../../assets/styles/constants';
import Video from '@components/Video';
import { isOffline } from '@utils';
import BackgroundImage from 'gatsby-background-image';

class ProductVideo extends React.Component {
  state = {
    showVideo: false,
  }
  
  handleOnClickPlayButton = () => {
    this.props.disableScrolling(false);

    this.setState({
      showVideo: true
    });
  }

  handleCloseVideo = () => {
    this.props.disableScrolling(false);

    this.setState({
      showVideo: false
    });
  }

  render() {
    const { showVideo } = this.state
    const { videoPath, videoTitle, showTitleAbove, placeholder, style } = this.props
    const image = get(placeholder, 'localFile.childImageSharp.fluid');

    if (!videoPath) {
      return null;
    }

    return (
      <section className="section">
        <ProductVideoWrapper style={style}>
          {showVideo && (
            <Video
              path={videoPath}
              onClose={this.handleCloseVideo}
              onEnded={this.handleCloseVideo}
              backButtonLabel="Go back"
            />
          )}
          {videoTitle && showTitleAbove && (
            <VideoTitle hide={showVideo} dangerouslySetInnerHTML={{ __html: videoTitle }} />
          )}
          <ProductVideoInner videoIsPlaying={showVideo}>
            <PlaceholderBackgroundImage
              fluid={image}
              hide={showVideo}
              critical={isOffline}
              fadeIn="soft"
            />
            <Content className="product-video-content">
              {videoTitle && !showTitleAbove && (
                <VideoTitle hide={showVideo} dangerouslySetInnerHTML={{ __html: videoTitle }} />
              )}
              <PlayButton className="product-video-playbutton" onClick={this.handleOnClickPlayButton}>
                <PlayIcon>
                  <svg viewBox="0 0 12 11">
                    <path
                      d="M11.6220636,5.28063105 L1.23975127,0.0523628255 C1.11989407,-0.0090259396 1.01690504,-0.0160729927 0.930921118,0.0311472256 C0.844937195,0.0783426302 0.802,0.163353911 0.802,0.286131441 L0.802,10.7143308 C0.802,10.8372076 0.844964578,10.9222933 0.930921118,10.9695135 C1.01690504,11.0167089 1.11989407,11.0097611 1.23975127,10.9482979 L11.6220636,5.72000488 C11.7420303,5.65851686 11.802,5.58516795 11.802,5.50025593 C11.802,5.41524465 11.7420029,5.34204462 11.6220636,5.28063105 Z"
                    />
                  </svg>
                </PlayIcon>
                <span>Watch the film</span>
              </PlayButton>
            </Content>
          </ProductVideoInner>
        </ProductVideoWrapper>
      </section>
    )
  }
}

export default ProductVideo

const ProductVideoWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const VideoTitle = styled.h2`
  text-align: center;
  padding: 0 1.5rem;
  margin: 0 0 3.5rem 0;
  font-weight: 400;
  line-height: 1.4;
  transition: 0.07s opacity;
  opacity: ${props => props.hide ? 0 : 1};
  font-size: 2rem;
  color: #FFF;

  @media screen and (${MQ.lg}) {
    font-size: 2.8rem;
  }
`

const PlaceholderBackgroundImage = styled(BackgroundImage)`
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${props => props.hide ? 0 : 1} !important;
  transition: opacity 0.4s;
`

const ProductVideoInner = styled.div`

  background: ${props => (props.videoIsPlaying ? '#fff' : 'transparent')};
  transition: all 0.5s ${cubicBezier};
  position: relative;
  width: ${props => (props.videoIsPlaying ? '100vw' : '80vw')};
  height: ${props => (props.videoIsPlaying ? '100%' : '40vw')};

  @media screen and (${MQ.lg}) {
    width: ${props => (props.videoIsPlaying ? '100vw' : '60vw')};
    height: ${props => (props.videoIsPlaying ? '100%' : '30vw')};
  }

  > video {
    display: block;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.4s;
  }

  ${props =>
    props.videoIsPlaying &&
    `
      > video {
        opacity: 1;
      }
      .product-video-content {
        background: rgba(1, 1, 1, 0);
        height: calc(100% - 75px);
      }
      .product-video-playbutton {
        bottom: -60vh;
      }
      .product-video-closebutton {
        top: 30px;
        transition: all 0.5s ${cubicBezier} 1s,
                    transform 0.5s ${cubicBezier},
                    opacity 0.5s ${cubicBezier};
      }
    `}
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(1, 1, 1, 0.5);
  transition: all 0.5s ${cubicBezier};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
`

const PlayButton = styled.div`
  display: flex;
  position: relative;
  bottom: 0;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: all 0.5s ${cubicBezier};
  transform: scale(1);
  opacity: 1;
  color: #FFF;

  @media screen and (${MQ.lg}) {
    &:hover {
      transform: scale(1.2);
    }
  }

  > span {
    font-size: 1.15rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    margin-left: 30px;
  }
`

const PlayIcon = styled.div`
  width: 3rem;
  height: 3rem;
  background: transparent;
  border-radius: 100%;
  border: 1px solid #FFF;
  box-shadow: 0 0 1px 0px #FFF inset, 0 0 1px 0px #FFF;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 0.6rem;
    height: 0.6rem;
    fill: #FFF;
  }

  &:focus {
    outline: none;
  }
`

export const pagebuilderVideoQuery = graphql`
  fragment SectionMenuVideo on WpPage_Pagebuilder_Sections_Video {
    ... on WpPage_Pagebuilder_Sections_Video {
      sectionTitle
    }
  }
  fragment PagebuilderVideo on WpPage_Pagebuilder_Sections_Video {
    ... on WpPage_Pagebuilder_Sections_Video {
      
      sectionTitle
      videoPath
      videoTitle
      showTitleAboveVideo
      placeholder {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
