/**
 * Pagebuilder -> sections -> Menu
*/

import React, { memo } from 'react';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import { isOffline } from '@utils';
import { cubicBezier, MQ } from '../../../assets/styles/constants';

const CategoryProducts = memo(({ items }) => (
  <StyledCategoryProducts>
    {items.map(item => (
      <CategoryProduct
        key={`menu-item-${item.uri}`}
        to={item.uri}
      >
        <CategoryProductTitle
          dangerouslySetInnerHTML={{ __html: item.title }} 
        />
      </CategoryProduct>
    ))}
  </StyledCategoryProducts>
));

const Menu = ({ parentPage, headline, backgroundImage }) => {
  const image = get(backgroundImage, 'localFile.childImageSharp.fluid',  null) ||
    get(parentPage, 'options.mainMenuImage.localFile.childImageSharp.fluid', null);
  const items = get(parentPage, 'wpChildren.nodes') || [];
  const title = headline || parentPage.title;

  return (
    <Section className="section" data-theme="dark">
      <CollectionSection>
        <ImageBackground>
          {image && (
            <BackgroundImage
              critical={isOffline}
              fluid={image}
              style={{
                height: '100%',
                width: '100%',
              }}
            />
          )}
        </ImageBackground>
        <Content>
          <Headline to={parentPage.uri} dangerouslySetInnerHTML={{ __html: title }} />
          {items.length ? (<CategoryProducts items={orderBy(items, item => item.menuOrder)} />) : null}
        </Content>
      </CollectionSection>
    </Section>
  );
};

export default Menu;

export const PagebuilderMenuQuery = graphql`
  fragment SectionMenuMenu on WpPage_Pagebuilder_Sections_Menu {
    ... on WpPage_Pagebuilder_Sections_Menu {
      sectionTitle
    }
  }
  fragment PagebuilderMenu on WpPage_Pagebuilder_Sections_Menu {
    ... on WpPage_Pagebuilder_Sections_Menu {
      sectionTitle
      headline
      backgroundImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1400, srcSetBreakpoints: [700]) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      parentPage {
        ... on WpPage {
          title
          uri
          options {
            mainMenuImage {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1400, srcSetBreakpoints: [700]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          wpChildren {
            nodes {
              ... on WpPage {
                title
                uri
                menuOrder
              }
            }
          }
        }
      }
    }
  }
`;

const CollectionSection = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #FFF;
`

const Section = styled.div``

const Content = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;

  .cplogo {
    transform: scale(0.8);
    @media screen and (${MQ.lg}) {
      transform: scale(1);
    }
  }
`

const Headline = styled(Link)`
  display: block;
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: currentColor;
`

const ImageBackground = styled.div`
  user-select: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 1, 1, 0.5);
    z-index: 2;
  }
`

const StyledCategoryProducts = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  margin-top: 3rem;
  padding-left: 12vw;
  padding-right: 12vw;
`

const CategoryProduct = styled(Link)`
  transform: scale(1);
  transition: all 0.5s ${cubicBezier};
  user-select: none;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  border-radius: 0;
  line-height: 1.4;
  margin: 1rem;
  background-color: rgba(255, 255, 255, 0.5);

  &:hover, &:focus {
    background-color: #000;
    color: #fff;
  }

  @media screen and (min-width: 1000px) {
    flex: 33.3333%;
  }
}
`

const CategoryProductTitle = styled.span`
  display: block;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 4px;
`