/**
 * Streaming -> VideoManager
 */

import React, { useState, useCallback } from 'react';
import get from 'lodash/get';
import Video from '@components/Video';
import { isOffline } from '@utils';
import css from './VideoManager.module.css';
import { PlayNextVideoNotification } from './components';

const VideoManager = ({ episodes, onCloseVideo, onPlayEpisode, video, show, persisted }) => {
  const [videoState, setVideoState] = useState(null);
  const [autoplayNext, setAutoplayNext] = useState(null);
  const handleAutoPlayNext = useCallback(episode => setAutoplayNext(episode), []);

  if (!video || !show) {
    return null;
  }

  const { currentTime, ended } = get(persisted, video.slug, {});
  const initialVolume = get(persisted, 'volume');


  const handleCloseVideo = episode => {
    setVideoState(null);
    onCloseVideo(episode);
  }

  const handleEndVideo = episode => {
    if (autoplayNext) {
      setVideoState(null);
      onPlayEpisode(autoplayNext);
    } else {
      handleCloseVideo(episode);
    }
  };

  return (
    <>
      {!isOffline && (
        <PlayNextVideoNotification 
          episodes={episodes}
          currentVideo={video}
          videoState={videoState}
          onPlayEpisode={onPlayEpisode}
          onAutoplayNext={handleAutoPlayNext}
        />
      )}
      <Video
        className={css.video}
        backButtonLabel="Go back"
        captions={video.captions}
        // Reset the start time if the video has ended once already
        startTime={ended ? 0 : currentTime}
        initialVolume={initialVolume}
        meta={video}
        onClose={handleCloseVideo}
        onEnded={handleEndVideo}
        path={video.videoPath}
        show={show}
        onVideoStateChange={state => setVideoState(state)}
      />
    </>
  )
}

export default VideoManager;
