import React, { useState } from 'react';
import styled from 'styled-components';

import { cubicBezier } from '../../../assets/styles/constants'

const ProgressIndicator = ({ items, selected, itemType, onItemClick, presentationMode }) => {
  // Progress menu can be toggled in presentation mode
  const [visible, setVisible] = useState(!presentationMode);
  const itemsToLoop = []
  for (let index = 0; index < items; index++) {
    itemsToLoop.push(index)
  }

  const Item = itemType === 'number' ? ProgressIndicatorNumber : ProgressIndicatorDot;

  if (itemsToLoop.length === 1) {
    return null;
  }

  return (
    <ProgressIndicatorWrapper presentationMode={presentationMode} isVisible={visible} className="presentation-mode-control">
      <ProgressIndicatorInner isVisible={visible}>
        {itemsToLoop.map((item, index) => (
          <Item
            onClick={typeof onItemClick === 'function' ? () => onItemClick(index) : null}
            type={itemType}
            selected={selected === index}
            key={`ProgressIndicatorItem-${index}`}
          >
            {itemType === 'number' ? index + 1 : null}
          </Item>
        ))}
      </ProgressIndicatorInner>
      {presentationMode && (
        <InvisibleToggleButton onClick={() => setVisible(!visible)}>
          {selected}
        </InvisibleToggleButton>
      )}
    </ProgressIndicatorWrapper>
  )
}

ProgressIndicator.defaultProps = {
  presentationMode: false,
}

export default ProgressIndicator

const ProgressIndicatorWrapper = styled.div`
  position: fixed;
  display: flex;
  right: 15px;
  z-index: 900;
  top: 0;
  bottom: 0;
  width: ${props => props.presentationMode ? '200px' : 'auto'};
}
`

const ProgressIndicatorInner = styled.div`
  display: ${props => props.isVisible ? 'flex' : 'none' };
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem 0;
  max-height: 100%;
`

const ProgressIndicatorDot = styled.div`
  float: right;
  clear: both;
  background: ${props => (props.selected ? 'var(--color-figure, #000)' : 'transparent')};
  height: 10px;
  width: 10px;
  border: 1px solid var(--color-figure, #000);
  border-radius: 100%;
  opacity: ${props => (props.selected ? '1' : '0.3')};
  transition: all 0.5s ${cubicBezier};
  user-select: none;
  margin: 5px;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }  
`

const ProgressIndicatorNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.selected ? '#000' : 'transparent')};
  color: ${props => (props.selected ? '#fff' : '')};
  font-weight: ${props => (props.selected ? 'bold' : '')};
  height: 22px;
  width: 22px;
  line-height: 1;
  font-size: 12px;
  border: 1px solid #000;
  border-radius: 100%;
  opacity: ${props => (props.selected ? '0.5' : '0.3')};
  transition: all 0.5s ${cubicBezier};
  margin: 5px;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`

const InvisibleToggleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  right: 0;
  opacity: 0;

  &:hover {
    opacity: 1;
    cursor: pointer;
    background-color: #000;
    color: #fff;
  }
`