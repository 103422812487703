/**
 * Default page
 */

import React, { useRef } from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import { SectionMenu, DefaultMainMenu } from '@components';
import Pagebuilder from '../../pagebuilder/Pagebuilder';

const DefaultPage = ({ data }) => {
  const fullpageRef = useRef(null);
  const page = get(data, 'page', {});
  const sections = get(page, 'pagebuilder.sections') || [];
  const options = get(page, 'options', {});
  const parent = get(page, 'parent.node', null);
  const { uri, databaseId } = page;
  const { hideOverviewMenu, hideMainMenu, showScrollIndicator, scrollIndicatorLabel } = options;

  const handleScrollToSection = (sectionIndex) => {
    fullpageRef.current.fullpageApi.moveTo(sectionIndex + 1);
  }

  return (
    <>
      {!hideMainMenu && <DefaultMainMenu />}
      <Pagebuilder 
        sections={sections}
        ref={fullpageRef}
        wordpressId={databaseId}
        showScrollIndicator={showScrollIndicator}
        scrollIndicatorLabel={scrollIndicatorLabel}
      >
        {({ activeIndex, hideControls }) => {
          if (hideOverviewMenu || hideControls) {
            return null;
          }

          return (
            <SectionMenu 
              parent={parent}
              currentUri={uri}
              onScrollToSection={handleScrollToSection}
              currentSectionIndex={activeIndex}
            />
          )
        }}
      </Pagebuilder>
    </>
  );
}

export default DefaultPage;

export const query = graphql`
fragment DefaultPageFields on WpPage {
  template {
    __typename
  }
  databaseId
  uri
  title
  options {
    categoryImage {
      localFile {
        publicURL
      }
    }
    categoryTitle
    hideMainMenu
    hideOverviewMenu
    mainMenuImage {
      localFile {
        publicURL
      }
    }
    overviewMenuTitle
    showInMainMenu
    showInOverviewMenu
    showScrollIndicator
    scrollIndicatorLabel
  }
  parent: wpParent {
    node {
      ... on WpPage {
        title
        uri
        wpChildren {
          nodes {
            ... on WpPage {
              menuOrder
              title
              uri
              options {
                showInOverviewMenu
                overviewMenuTitle
              }
              pagebuilder {
                sections {
                  ...SectionMenuContent
                  ...SectionMenuFullscreen
                  ...SectionMenuHero
                  ...SectionMenuIntro
                  ...SectionMenuMenu
                  ...SectionMenuProducts
                  ...SectionMenuStreaming
                  ...SectionMenuVideo
                  ...SectionMenuWearing
                  ...SectionMenuTextual
                }
              }
            }
          }
        }
      }
    }
  }
  ...Pagebuilder
}

  query Page($id: String) {
    page: wpPage(id: { eq: $id }) {
      template {
        __typename
      }
      databaseId
      uri
      title
      ...DefaultPageFields
    }
  }
`;
