/**
 * Pagebuilder -> sections -> Content
 */

import React from 'react'
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Images from './Images';
import { cubicBezier, MQ } from '../../../assets/styles/constants'

class ProductContent extends React.Component {
  constructor(props) {
    super(props)

    this.flickityRef = React.createRef();
    this.expandedContentRef = React.createRef();

    this.state = {
      showModal: false
    }
  }

  componentDidUpdate(prevProps) {
    const { expandedText, isActive } = this.props

    // Add event listener when the section is active remove again when it's not
    if (expandedText) {
      if (prevProps.isActive !== false && isActive) {
        document.addEventListener('keydown', this.handleEsc, true);
      } else if (prevProps.isActive && !isActive) {
        document.removeEventListener('keydown', this.handleEsc, true);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEsc, true);
  }

  handleEsc = ({ key }) => {
    if (this.state.showModal && key === 'Escape') {
      this.toggleModal(false);
    }
  }

  toggleModal = open => {
    if (open && this.expandedContentRef && this.expandedContentRef.current) {
      this.expandedContentRef.current.focus();
    }

    this.props.disableScrolling(open);

    this.setState({
      showModal: open,
    });
  }

  render() {
    const { showModal } = this.state;
    const {
      headline,
      headlineFontSize,
      text,
      expandedText,
      images,
      id,
      textPlacement,
      imageStyle,
    } = this.props

    const hasImages = Array.isArray(images) && images.length;

    return (
      <section className="section">
        <ProductContentWrapper>
          <ProductContentInner textPlacement={textPlacement}>
            <Images images={images} imagesFullHeight={imageStyle === 'fullheight'} id={id} />
            <Content hasImages={hasImages}>
              <ContentInner hasImages={hasImages}>
                <Headline size={headlineFontSize || 'large'} hasImages={hasImages} dangerouslySetInnerHTML={{ __html: headline }} />
                <ContentText
                  dangerouslySetInnerHTML={{ __html: text }}
                  hasImages={hasImages}
                />
                {expandedText && (
                  <ExpandedTextTrigger showModal={showModal} onClick={() => this.toggleModal(true)} />
                )}
              </ContentInner>
            </Content>
          </ProductContentInner>
          {expandedText && (
            <ExpandedContentWrapper ref={this.expandedContentRef} className="overlay" showModal={showModal} tabIndex="0">
              <ExpandedContentText dangerouslySetInnerHTML={{ __html: expandedText }} />
              <ExpandedContentCloseButton onClick={() => this.toggleModal(false)} />
            </ExpandedContentWrapper>
          )}
        </ProductContentWrapper>
      </section>
    )
  }
}

export default ProductContent

const ProductContentWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
`

const ProductContentInner = styled.div`
  display: flex;
  flex-direction: ${props => (props.textPlacement === 'right' ? 'row' : 'row-reverse')};
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${props => (props.hasImages ? '50vw' : '100vw')};
  height: 100vh;
`

const ContentInner = styled.div`
  text-align: ${props => (props.hasImages ? 'left' : 'center')};
  font-size: ${props => (props.hasImages ? '1.2rem' : '1.4rem')};
  max-width: 85rem;
  padding: 0 7rem 0 4rem;

  @media screen and (min-width: 1400px) {
    padding: 0 6.5rem;
  }
`

const Headline = styled.span`
  display: block;
  font-size: ${({ size }) => {
    switch(size) {
      case 'small':
        return '1.7rem';
      case 'medium':
        return '2.7rem';
      case 'large':
      default:
        return '3.5rem';
    }
  }};
  letter-spacing: 0.2rem;
  text-transform: uppercase;
`

const ContentText = styled.div`
  // padding: 0 6rem;
  ul {
    max-width: 48rem;
    margin: auto;
    text-align: left;
  }
`

const ExpandedContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1100;
  height: 100%;
  width: 100vw;
  padding: 60px 120px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: 0;
  background: rgba(1, 1, 1, 0.95);
  opacity: ${props => (props.showModal ? 1 : 0)};
  transition: all 0.5s ${cubicBezier};

  pointer-events: ${props => (props.showModal ? 'auto' : 'none')};
  -webkit-backface-visibility: hidden;
  user-select: none;
`

const ExpandedContentText = styled.div`
  padding-bottom: 3rem;
  max-width: 52rem;
  font-size: 1.3rem;
  margin: 0 auto;
  color: #FFF;

  ul {
    max-width: 48rem;
    margin: auto;
    text-align: left;
  }
`

const ExpandedTextTrigger = styled.button`
  width: 60px;
  height: 60px;
  background: transparent;
  border-radius: 100%;
  border: 1px solid #000;
  box-shadow: 0 0 1px 0px #000 inset, 0 0 1px 0px #000;
  line-height: 60px;
  cursor: pointer;
  transition: all 0.5s ${cubicBezier};
  user-select: none;
  position: relative;
  transform: ${props => (props.showModal ? 'rotate(315deg)' : 'rotate(0deg)')};

  &:before,
  &:after {
    content: '';
    background: #000;
    height: 1px;
    width: 24px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
  }

  &:after {
    transform: rotate(90deg);
  }

  @media screen and (${MQ.lg}) {
    &:hover {
      transform: ${props => (props.showModal ? 'rotate(315deg)' : 'rotate(25deg)')};
    }
  }

  &:focus {
    outline: none;
  }
`

const ExpandedContentCloseButton = styled.div`
  margin: 0 auto;
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  background: transparent;
  border-radius: 100%;
  border: 1px solid #FFF;
  box-shadow: 0 0 1px 0px #FFF inset, 0 0 1px 0px #FFF;
  line-height: 60px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  position: relative;
  transition: all 0.5s ${cubicBezier}, transform 0.5s ${cubicBezier}, opacity 0.5s ${cubicBezier};
  transform: scale(1);

  &:before,
  &:after {
    content: '';
    background: #FFF;
    height: 1px;
    width: 24px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(135deg);
  }

  @media screen and (${MQ.lg}) {
    &:hover {
      transform: scale(1.2);
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
`

export const PagebuilderContentQuery = graphql`
  fragment SectionMenuContent on WpPage_Pagebuilder_Sections_Content {
    ... on WpPage_Pagebuilder_Sections_Content {
      sectionTitle
    }
  }
  fragment PagebuilderContent on WpPage_Pagebuilder_Sections_Content {
    ... on WpPage_Pagebuilder_Sections_Content {
      sectionTitle
      headline
      headlineFontSize
      text
      expandedText
      textPlacement
      imageStyle
      images {
        image {
          localFile {
            childImageSharp {
              fixed(width: 1400) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;
