/**
 * Templates -> Products
 */

import React, { memo, useRef, useEffect, useState, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import Image from 'gatsby-image';
import ScrollBar from 'react-overlayscrollbars';
import css from './Products.module.scss';
import { ProductItem, ProductOverlay } from './components';

const removeHashFromUrl = () => {
  window.history.pushState("", document.title, window.location.pathname + window.location.search);
}

const Products = ({ productCategories }) => {
  const scrollRef = useRef(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const allProducts = useMemo(() => productCategories.reduce(
    (acc, cat) => acc.concat((get(cat, 'productCategory.products.nodes', []) || [])), []), 
    [productCategories]
  );

  // Set product on load
  useEffect(() => {
    const slug = window.location.hash.replace('#', '');

    if (slug) {
      const product = allProducts.find(p => p.slug === slug);
      if (product) {
        setSelectedProduct(product);
      } else {
        // Remove invalid hash
        removeHashFromUrl();
      }
    }
  }, [allProducts]);

  const onProductChange = ({ id, slug }) => {
    window.location.hash = `#${slug}`;
    if (scrollRef && scrollRef.current) {
      const productEl = document.getElementById(id);

      if (productEl) {
        scrollRef.current.scrollBars.scroll(productEl);
      }
    }
  }

  useEffect(() => {
    if (selectedProduct) {
      onProductChange(selectedProduct);
    }
  }, [selectedProduct]);

  const handleClickProduct = product => {
    setSelectedProduct(product);
  }

  const handleCloseOverlay = () => {
    setSelectedProduct(null);
    removeHashFromUrl();
  };

  const handleNext = useCallback(currId => {
    const currIndex = allProducts.findIndex(p => p.id === currId);
    const nextProduct = currIndex === allProducts.length - 1 ? allProducts[0] : allProducts[currIndex + 1];
    setSelectedProduct(nextProduct);
  }, [allProducts]);

  const handlePrevious = useCallback(currId => {
    const currIndex = allProducts.findIndex(p => p.id === currId);
    const previousProduct = currIndex === 0 ? allProducts[allProducts.length - 1] : allProducts[currIndex - 1];
    setSelectedProduct(previousProduct);
  }, [allProducts]);

  const renderProduct = (product) => {
    const { id, slug, title, data } = product; 

    return (
      <ProductItem
        data={data}
        id={id}
        key={id}
        onClick={() => handleClickProduct(product)}
        title={title}
        slug={slug}
      />
    );
  }

  const renderProductCategories = () => {
    return productCategories.map(({ id, productCategory, headline, sub, logo }) => {
      const products = get(productCategory, 'products.nodes', []) || [];
      const sortedProducts = sortBy(products, product => product.title);

      return (
        <div key={id}>
          <div className={css.header}>
            {logo && (
              <Image
                className={css.logo}
                fluid={logo.localFile.childImageSharp.fluid}
              />
            )}
            {headline && (
              <h1 className={css.title}>
                {headline}
              </h1>
            )}
            {sub && (
              <h2 className={css.sub}>
                {sub}
              </h2>
            )}
          </div>
          {products.length ? (
            <ul className={css.products}>
              {sortedProducts.map(renderProduct)}
            </ul>
          ) : null}
        </div>
      )
    })
  }

  return (
    <div className="section">
      {selectedProduct && (
        <ProductOverlay 
          product={selectedProduct}
          onClose={handleCloseOverlay}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
      )}
      <ScrollBar 
        ref={scrollRef}
        className={classnames('os-theme-dark', css.root)}
        overflow-behavior={{ x: 'hidden' }}
        scrollbars={{ autoHide: 'l' }}
      >
        <div>
          {renderProductCategories()}
        </div>
      </ScrollBar>
    </div>
  );
}

export default memo(Products);