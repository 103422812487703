/**
 * Pagebuilder -> sections -> Textual
*/

import React from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import ScrollBar from 'react-overlayscrollbars';
import css from './Textual.module.css';

const Textual = ({ headline, content }) => {
  return (
    <section className={classnames('section', css.root)}>
      <ScrollBar 
        className={classnames('os-theme-dark', css.root)}
        overflow-behavior={{ x: 'hidden' }}
        scrollbars={{ autoHide: 'l' }}
      >
        <div className={css.content}>
          {headline && <h1 className={css.title}>{headline}</h1>}
          {content && <div className={css.text} dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
      </ScrollBar>
    </section>
  );
};

export default Textual;

export const PagebuilderTextualQuery = graphql`
  fragment SectionMenuTextual on WpPage_Pagebuilder_Sections_Textual {
    ... on WpPage_Pagebuilder_Sections_Textual {
      sectionTitle
    }
  }
  fragment PagebuilderTextual on WpPage_Pagebuilder_Sections_Textual {
    ... on WpPage_Pagebuilder_Sections_Textual {
      headline
      content
    }
  }
`;
