/**
 * Pagebuilder -> Sections -> Products -> Query
 */

import React from 'react'
import useWithIds from '@utils/useWithIds';
import { graphql } from 'gatsby';
import Products from './Products';
 
const ProductsQuery = ({ productCategories, ...data }) => {
  const categories = useWithIds(productCategories);
  return <Products productCategories={categories} />;
}

export default ProductsQuery;
 
export const pagebuilderProductsQuery = graphql`
  fragment SectionMenuProducts on WpPage_Pagebuilder_Sections_Products {
    ... on WpPage_Pagebuilder_Sections_Products {
      sectionTitle
    }
  }
  fragment PagebuilderProducts on WpPage_Pagebuilder_Sections_Products {
    ... on WpPage_Pagebuilder_Sections_Products {
      sectionTitle
      productCategories {
        headline
        sub
        logo {
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        productCategory {
          name
          products {
            nodes {
              id
              title
              slug
              data {
                logos
                barcode
                id
                viewerUrl
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                largeImage: image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
 