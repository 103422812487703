/**
 * Pagebuilder -> sections -> Hero
*/

import React from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { isOffline } from '@utils';
import { FullscreenVideo } from '@components';
import css from './Hero.module.css';

const Hero = ({ title, subtitle, videoPath, inView, logo, backgroundImage, backgroundType }) => {
  const backgroundImageFluid = get(backgroundImage, 'localFile.childImageSharp.fluid', null);
  const theme = backgroundType === 'none' ? 'light' : 'dark';

  return (
    <section className={classnames('section', css.root)} data-theme={theme}>
      <div className={css.inner}>
        {backgroundType === 'video' && videoPath && (
          <div className={css.videoBackground}>
            <FullscreenVideo
              autoPlay 
              muted
              loop
              preload="auto"
              path={videoPath}
              inView={inView}
            />
          </div>
        )}
        {backgroundType === 'image' && backgroundImageFluid && (
          <BackgroundImage
            className={css.backgroundImage}
            critical={isOffline}
            fluid={backgroundImageFluid}
            fadeIn="soft"
          />
        )}
        <div className={css.content}>
          <p className={css.ecco}>ECCO</p>
          {logo && (
            <Image
              className={css.logo}
              loading={isOffline ? 'eager' : 'lazy'}
              fluid={get(logo, 'localFile.childImageSharp.fluid')}
              alt=""
            />
          )}
          {title && <h1 className={css.title} dangerouslySetInnerHTML={{ __html: title }} />}
          {subtitle && <h2 className={css.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />}
        </div>
      </div>
    </section>
  );
};

export default Hero;

export const PagebuilderHeroQuery = graphql`
  fragment SectionMenuHero on WpPage_Pagebuilder_Sections_Hero {
    ... on WpPage_Pagebuilder_Sections_Hero {
      sectionTitle
    }
  }
  fragment PagebuilderHero on WpPage_Pagebuilder_Sections_Hero {
    ... on WpPage_Pagebuilder_Sections_Hero {
      subtitle
      title
      videoPath
      backgroundType
      backgroundImage {
        sourceUrl
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000, srcSetBreakpoints: [1200]) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      logo {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
