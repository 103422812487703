/**
 * Pagebuilder -> sections -> Fullscreen
 */

import React, { memo } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Images from './Images'
import { FullscreenVideo } from '@components';

const Fullscreen = memo(props => {
  const { video, videoPath, fullscreenBackgroundImages, text, id, inView } = props
  const hasVideo = !!videoPath || !!video;

  return (
    <section className="section">
      <FullscreenWrapper>
        {hasVideo ? (
          <VideoBackground>
            <FullscreenVideo 
              autoPlay
              muted
              loop
              // preload="auto"
              path={videoPath}
              inView={inView}
            />
          </VideoBackground>
        ) : (
          <Images images={fullscreenBackgroundImages} id={id} />
        )}
        {text && (
          <Content>
            <ContentText dangerouslySetInnerHTML={{ __html: text }} />
          </Content>
        )}
      </FullscreenWrapper>
    </section>
  )
})

export default Fullscreen

const VideoBackground = styled.div`
  user-select: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  > video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
`

const FullscreenWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const Content = styled.div`
  font-size: 2.6rem;
  position: absolute;
  background-color: rgba(1, 1, 1, 0.3);
  padding: 2rem;
  z-index: 2;
  bottom: 5rem;
  left: 5rem;
  width: 100%;
  max-width: 60rem;
  color: #FFF;

  p {
    line-height: 1.4;
    margin: 0;
  }
`

const ContentText = styled.div``

export const PagebuilderFullscreenQuery = graphql`
  fragment SectionMenuFullscreen on WpPage_Pagebuilder_Sections_Fullscreen {
    ... on WpPage_Pagebuilder_Sections_Fullscreen {
      sectionTitle
    }
  }
  fragment PagebuilderFullscreen on WpPage_Pagebuilder_Sections_Fullscreen {
    ... on WpPage_Pagebuilder_Sections_Fullscreen {
      sectionTitle
      text
      videoPath
      fullscreenBackgroundImages {
        adjustment
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000, srcSetBreakpoints: [1200]) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;