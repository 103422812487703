/**
 * Templates -> Products -> ProductItem
 */

import React, { memo } from 'react';
import { Link } from 'gatsby';
import get from 'lodash/get';
import Image from 'gatsby-image';
import css from './ProductItem.module.scss';

const ProductItem = memo(({ id, title, slug, data, onClick }) => {
  const image = get(data, 'image.localFile.childImageSharp.fluid');
  const { barcode: articleNumber, id: textBelowArticleNumber } = data;

  return (
    <li className={css.products__item} id={id}>
      <Link onClick={onClick} to={`#${slug}`} className={css.products__item__inner}>
        {image && (
          <Image
            fluid={image}
            className={css.products__item__image}
          />
        )}
        <div className={css.products__item__content}>
          <h3 className={css.products__item__content__name}>
            {title}
          </h3>
          {articleNumber && <p>{articleNumber}</p>}
          {textBelowArticleNumber && <p className={css.textBelowArticleNumber}>{textBelowArticleNumber}</p>}
        </div>
      </Link>
    </li>
  );
});

export default ProductItem;