import React from 'react'
import get from 'lodash/get';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import { isOffline } from '@utils';
import { FlickityArrow } from '@components';
import Flickity from 'react-flickity-component';

class ProductFullscreenImages extends React.Component {
  constructor(props) {
    super(props)

    this.flickityRef = React.createRef()
  }

  state = {
    animate: false,
    disablePrevButton: true,
    disableNextButton: false,
  }

  componentDidMount() {
    this.setState({
      animate: true, 
    }, () => {
      setTimeout(() => {
        this.setState({
          animate: false
        })
      }, 1000) 
    });
  }

  handleOnFlickityClick = isLeft => {
    const flickityInstance = this.flickityRef.current.flkty

    flickityInstance.on('change', () => {
      if (flickityInstance.selectedIndex === 0) {
        this.setState({
          disablePrevButton: true,
          disableNextButton: false,
        })
      } else if (flickityInstance.selectedIndex === this.props.images.length - 1) {
        this.setState({
          disablePrevButton: false,
          disableNextButton: true,
        })
      } else {
        this.setState({
          disablePrevButton: false,
          disableNextButton: false,
        })
      }
    })

    if (isLeft) {
      flickityInstance.previous()
    } else {
      flickityInstance.next()
    }
  }

  render() {
    const { images, id } = this.props
    const { animate, disableNextButton, disablePrevButton } = this.state

    if (!images || !images.length) {
      return null
    }

    // Only one image – just show the image without slider functionality
    if (images.length === 1) {
      const image = images[0];

      return (
        <ProductImages>
          <BackgroundImage
            critical={isOffline}
            fluid={get(image, 'image.localFile.childImageSharp.fluid')}
            style={{
              height: '100%',
              width: '100%',
              backgroundSize: get(image, 'adjustment', 'cover'),
            }}
            />
        </ProductImages>
      );
    }
    return (
        <ProductImages>
          <FlickityWrapper>
            <FlickityArrowsWrap className="presentation-mode-control">
              <FlickityArrow
                hide={disablePrevButton}
                direction="left"
                onClick={() => this.handleOnFlickityClick(true)}
              />
              <FlickityArrow
                hide={disableNextButton}
                onClick={() => this.handleOnFlickityClick()}
                animate={animate}
              />
            </FlickityArrowsWrap>
            {typeof window !== 'undefined' && Flickity && (
              <Flickity
                static
                ref={this.flickityRef}
                className="flickity-carousel"
                options={{
                  prevNextButtons: false,
                  setGallerySize: false,
                }}
              >
                {images.map((data, index) => {
                  const image = get(data, 'image.localFile.childImageSharp.fluid', null);
                  const backgroundSize = get(data, 'adjustment', 'cover');

                  if (!image) {
                    return null;
                  }

                  return (
                    <div key={`product-gallery-${id}-${index}`} className="carousel-cell">
                      <BackgroundImage
                        fluid={image}
                        fadeIn="soft"
                        style={{
                          width: '100%',
                          height: '100%',
                          backgroundSize,
                        }}
                      />
                    </div>
                  )
                })}
              </Flickity>
            )}
          </FlickityWrapper>
      </ProductImages>
    )
  }
}

export default ProductFullscreenImages

const FlickityWrapper = styled.div`
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  user-select: none;
  outline: none;
  position: relative;
  height: 100%;
`

const FlickityArrowsWrap = styled.div`
  display: flex;
  position: absolute;
  z-index: 10;
  right: 3rem;
  left: 3rem;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
`


const ProductImages = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  user-select: none;

  // Gallery options
  .flickity-carousel {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    user-select: none;
    outline: none;
    position: relative;
    height: 100%;
  }
  .flickity-viewport {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .flickity-slider {
    height: 100%;
  }

  .carousel-cell {
    width: 100%;
    height: 100%;
  }
`
