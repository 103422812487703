/**
 * Streaming -> Hero
 */

import React from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import Image from 'gatsby-image';
import { isOffline } from '@utils';
import { Link } from 'gatsby';
import css from './Hero.module.css';

const Hero = ({ logo, backLink }) => {
  const image = get(logo, 'localFile.childImageSharp.fluid');

  return (
    <div className={css.root}>
      {backLink && backLink.show && backLink.label && (
        <Link to={backLink.path} className={classnames(css.backLink, 'presentation-mode-control')}>
          <svg className={css.backLink__icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" data-test-icon-element="true" focusable="false"><path d="M28.2 14.5h-20l6.2-6.2c.8-.8.8-2 0-2.8s-2-.8-2.8 0l-9.4 9.6c-.8.8-.8 2 0 2.8l9.6 9.6c.8.8 2 .8 2.8 0s.8-2 0-2.8l-6.2-6.2h20c1.2 0 2-.8 2-2s-1-2-2.2-2z"></path></svg>
          {backLink.label}
        </Link>
      )}
      {image && (
        <Image
          className={css.logo}
          loading={isOffline ? 'eager' : 'lazy'}
          fluid={image}
          alt=""
        />
      )}
    </div>
  )
}

export default Hero;
