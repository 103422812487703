/**
 * Templates -> Products -> ProductOverlay
 */

import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { createPortal } from 'react-dom';
import Image from 'gatsby-image';
import css from './ProductOverlay.module.scss';
import logoImages from '../../assets/logos/index';

const ControlButton = ({ onClick, className, children, faded, }) => (
  <button className={classnames(css.button, { [css.faded]: faded }, className)} onClick={onClick} aria-label="Close">
    {children}
  </button>
);

const ProductOverlay = ({ onClose, onNext, onPrevious, product }) => {
  const lastId = useRef(null);
  const [showImage, setShowImage] = useState(false);
  const { id, title, data: { largeImage: image, logos, barcode: articleNumber, id: textBelowArticleNumber, viewerUrl  } } = product;

  /**
   * A small hack to ensure that the gatsby image 
   * lazy load effect is fired each time a product changes
   * Not pretty but it gets the job done.
   */
  useEffect(() => {
    let timeout = null;

    if (lastId.current !== id) {
      lastId.current = id;
      setShowImage(false);
      timeout = setTimeout(() => {
        setShowImage(true);
      }, 10);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [id]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'Escape':
          onClose();
          break;
        case 'ArrowLeft':
          onPrevious(id);
          break;
        case 'ArrowRight':
          onNext(id);
          break;
        default:
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [onClose, onNext, onPrevious, id]);

  const renderPresentation = () => {
    if (viewerUrl) {
      return (
        <div className={css.viewer}>
          <iframe
            className={css.viewer__inner}
            src={viewerUrl}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            title={title}
          />
        </div>
      )
    }

    if (image && showImage) {
      return (
        <Image 
          className={css.image}
          fluid={image.localFile.childImageSharp.fluid}
          alt={title}
        />
      );
    }

    return null;
  }

  const content = (
    <div className={css.root}>
      <ControlButton 
        onClick={onClose} 
        className={css.button__close}
      >
        <svg className={css.button__icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M4.6 27.4c.8.8 2 .8 2.8 0l8.6-8.6 8.6 8.6c.8.8 2 .8 2.8 0s.8-2 0-2.8L18.8 16l8.6-8.6c.8-.8.8-2 0-2.8s-2-.8-2.8 0L16 13.2 7.4 4.6c-.8-.8-2-.8-2.8 0s-.8 2 0 2.8l8.6 8.6-8.6 8.6c-.8.8-.8 2 0 2.8z"/></svg>
      </ControlButton>
      <ControlButton 
        faded
        onClick={() => onPrevious(id)} 
        className={css.button__previous}
      >
        <svg className={css.button__icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" data-test-icon-element="true" focusable="false"><path d="M28.2 14.5h-20l6.2-6.2c.8-.8.8-2 0-2.8s-2-.8-2.8 0l-9.4 9.6c-.8.8-.8 2 0 2.8l9.6 9.6c.8.8 2 .8 2.8 0s.8-2 0-2.8l-6.2-6.2h20c1.2 0 2-.8 2-2s-1-2-2.2-2z"></path></svg>
      </ControlButton>
      <ControlButton 
        faded
        onClick={() => onNext(id)} 
        className={css.button__next}
      >
        <svg className={css.button__icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" data-test-icon-element="true" focusable="false"><path d="M28.2 14.5h-20l6.2-6.2c.8-.8.8-2 0-2.8s-2-.8-2.8 0l-9.4 9.6c-.8.8-.8 2 0 2.8l9.6 9.6c.8.8 2 .8 2.8 0s.8-2 0-2.8l-6.2-6.2h20c1.2 0 2-.8 2-2s-1-2-2.2-2z"></path></svg>
      </ControlButton>
      {showImage && <div className={css.content}>
        <div className={css.presentation}>
          {renderPresentation()}
        </div>
        <div className={css.content}>
          {title && <h1>{title}</h1>}
          {articleNumber && <p>{articleNumber}</p>}
          {textBelowArticleNumber && <p>{textBelowArticleNumber}</p>}
        </div>
        <div className={css.logosWrap}>
          {Array.isArray(logos) && (
            <ul className={css.logos}>
              {logos.map(key => {
                const logo = logoImages[key];

                if (!logo) {
                  return null;
                }

                return (
                  <li className={css.logos__item} key={key}>
                    <img src={logo} alt={key} />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>}
    </div>
  );

  return createPortal(
    content,
    document.body
  );
}

export default ProductOverlay;