import React, { memo } from 'react'
import get from 'lodash/get';
import styled from 'styled-components'
import { graphql } from 'gatsby';
import { MQ } from '../../../assets/styles/constants'
import { isOffline } from '@utils';
import BackgroundImage from 'gatsby-background-image';

const fontSizes = {
  DESKTOP: {
    small: '4rem',
    medium: '6rem',
    large: '8rem',
  }
}

const ProductIntro = memo(props => {
  const { headline, fontSize, backgroundImage: imageData } = props
  const image = get(imageData, 'localFile.childImageSharp.fluid', null);

  return (
    <section className="section">
      <ProductIntroWrapper>
        {image && (
          <ImageBackground>
            <BackgroundImage
              critical={isOffline}
              fluid={image}
              style={{ backgroundSize: 'contain', height: '100%', width: '100%' }}
              fadeIn="soft"
            />
          </ImageBackground>
        )}
        <Content>
          <Headline
            dangerouslySetInnerHTML={{ __html: headline }}
            size={fontSize}
          />
        </Content>
      </ProductIntroWrapper>
    </section>
  );
});

export default ProductIntro

const ProductIntroWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const ImageBackground = styled.div`
  user-select: none;
  position: absolute;
  height: 100vh;
  width: 100vw;
  max-width: 80%;
  z-index: 1;

  @media screen and (${MQ.lg}) {
    max-width: 1800px;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 85rem;
`

const Headline = styled.span`
  display: block;
  font-size: ${props => fontSizes.DESKTOP[get(props, 'size') || 'large']};
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-top: 1.5rem;
  line-height: 1;
  padding-left: 3rem;
  padding-right: 3rem;
  line-height: 1.2;
`

export const PagebuilderIntroQuery = graphql`
  fragment SectionMenuIntro on WpPage_Pagebuilder_Sections_Intro {
    ... on WpPage_Pagebuilder_Sections_Intro {
      sectionTitle
    }
  }
  fragment PagebuilderIntro on WpPage_Pagebuilder_Sections_Intro {
    ... on WpPage_Pagebuilder_Sections_Intro {
      sectionTitle
      headline
      fontSize
      backgroundImage {
        sourceUrl
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000, srcSetBreakpoints: [1200]) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
