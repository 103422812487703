/**
 * Pagebuilder -> sections -> Streaming -> query
 */

import React from 'react';
import { graphql } from 'gatsby';
import Streaming from './Streaming';

const StreamingQuery = data => (
  <div className="section">
    {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
    <Streaming {...data} />
  </div>
);

export default StreamingQuery;

export const PagebuilderStreamingQuery = graphql`
  fragment SectionMenuStreaming on WpPage_Pagebuilder_Sections_Streaming {
    ... on WpPage_Pagebuilder_Sections_Streaming {
      sectionTitle
    }
  }
  fragment PagebuilderStreaming on WpPage_Pagebuilder_Sections_Streaming {
    ... on WpPage_Pagebuilder_Sections_Streaming {
      sectionTitle
      streamingId
      streamingLogo {
        localFile {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      streamingBackgroundImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000, srcSetBreakpoints: [1200]) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      streamingBackLink {
        show
        label
        path
      }
      streamingEpisodes {
        eyebrow
        slug
        title
        description
        videoPath
        cover {
          localFile {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        captions {
          file {
            localFile {
              publicURL
            }
          }
          locale
          label
        }
      }
    }
  }
`;
